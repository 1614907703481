import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import "intersection-observer";
import anime from "animejs";
window.anime = anime;
Vue.use(VueRouter);

const routes = [
  {
    path: "/cookie",
    name: "cookie",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Cookie.vue")
  },
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/shrinkwrap",
    name: "shrinkwrap",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ShrinkWrap.vue")
  },
  {
    path: "/scaffold",
    name: "shrinkwrap",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Scaffold.vue")
  },
  {
    path: "/healthandsafety",
    name: "hs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HealthSafety.vue")
  },
  {
    path: "/contact",
    name: "contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contact.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 200);
  next();
});

export default router;
