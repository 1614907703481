<template>
  <div class="w-full h-screen">
    <hooper :settings="hooperSettings" style="height: 100%" @slide="slid">
      <slide
        class="h-full w-full flex justify-center items-end overflow-hidden transition-all"
      >
        <scaff-frame
          :visible="visibleSlide === 0 || visibleSlide === 3"
        ></scaff-frame>
      </slide>
      <slide
        class="h-full w-full flex justify-center items-end overflow-hidden transition-all"
      >
        <slide2 :visible="visibleSlide === 1"></slide2>
      </slide>
      <slide
        class="h-full w-full flex justify-center items-end overflow-hidden transition-all"
      >
        <slide3 :visible="visibleSlide === 2 || visibleSlide === -1"></slide3>
      </slide>
    </hooper>
  </div>
</template>

<script>
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
import ScaffFrame from "@/components/ScaffFrame";
import Slide2 from "@/components/Slide2";
import Slide3 from "@/components/Slide3";
export default {
  name: "Hero",
  components: { Slide3, Slide2, ScaffFrame, Hooper, Slide },
  data() {
    return {
      hooperSettings: {
        itemsToShow: 1,
        autoPlay: true,
        wheelControl: false,
        playSpeed: 3500,
        transition: 500,
        hoverPause: false,
        infiniteScroll: true
      },
      visibleSlide: 1
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    slid(slide) {
      this.visibleSlide = slide.currentSlide;
    }
  }
};
</script>

<style scoped></style>
