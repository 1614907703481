<template>
  <div id="app" class="overflow-hidden">
    <transition name="loader">
      <loader v-if="showLoader" :loaded="!loading"></loader>
    </transition>
    <transition name="fader">
      <router-view />
    </transition>
    <transition name="fade">
      <modal v-if="this.$store.state.modalOpen === true" class="text-white">
      </modal>
    </transition>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import Loader from "@/components/Loader";
export default {
  components: { Loader, Modal },
  data() {
    return { loading: true };
  },
  computed: {
    showLoader() {
      return (
        (this.$store.state.loadingSplash || this.loading) &&
        this.$route.name !== "cookie"
      );
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("page");

    if (myParam !== null) {
      this.$router.replace("/" + myParam);
    }

    window.addEventListener("load", () => {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    });
  }
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(-2000px);
}

.fader-enter-active,
.fader-leave-active {
  transition: all 0.8s;
}

.fader-leave-active {
  transition: opacity 0.1s;
}
.fader-enter, .fader-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.loader-enter-active,
.loader-leave-active {
  transition: all 1s;
}

.loader-leave-active {
  transition: opacity 1s;
}
.loader-enter, .loader-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
