<template>
  <div class="text-white p-4  ">
    <div class="flex flex-col items-center" v-if="noCookie">
      <p class="mb-8">
        We use some cookies to make the site work, others are used to help us
        improve the site. Is that ok?
      </p>
      <div>
        <button
          class="py-2 w-24 border border-white rounded"
          @click="cookie(false)"
        >
          No
        </button>
        <button
          class="py-2 w-24 ml-4 bg-brand-orange rounded"
          @click="cookie(true)"
        >
          Yes
        </button>
      </div>
      <p class="text-sm mt-8 text-center">
        Our cookie policy explains it all. You can
        <router-link to="/cookie" class="underline">go there</router-link> at
        any time to adjust your consent
      </p>
    </div>

    <div class="flex flex-col items-center" v-if="consentMessage">
      <p class="class=text-white">
        {{ consentMessage }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cookie",
  data() {
    return {
      noCookie: false,
      consented: false,
      consentMessage: false
    };
  },
  methods: {
    cookie(isConsented) {
      if (isConsented) {
        document.cookie = "consent=granted;Secure;max-age=2592000";
        this.$store.commit("setConsent", true);
      } else {
        document.cookie = "consent=denied;Secure;max-age=2592000";
        this.$store.commit("setConsent", false);
      }
      this.noCookie = false;
      this.consentMessage =
        "Thanks! We'll ask you again in 30 days.  You can go to the cookie policy at any time to change your choice.";
      setTimeout(() => {
        this.$store.commit("closeSplash");
      }, 1000);
    }
  },
  mounted() {
    const cookieExists = document.cookie
      .split("; ")
      .find(row => row.startsWith("consent="));

    if (typeof cookieExists !== "undefined") {
      const consent = cookieExists.split("=")[1];
      if (consent === "granted") {
        this.noCookie = false;
        this.consented = true;
        this.$store.commit("setConsent", true);
        this.$store.commit("closeSplash");
      } else {
        this.noCookie = false;
        this.$store.commit("setConsent", false);
        this.$store.commit("closeSplash");
      }
    } else {
      this.noCookie = true;
    }
  }
};
</script>

<style scoped></style>
