import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import "./assets/tailwind.css";
import router from "./router";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faEnvelope,
  faTimesCircle,
  faPhone,
  faArrowCircleLeft,
  faArrowCircleRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import titleMixin from "@/mixins";

Vue.mixin(titleMixin);

library.add(
  faBars,
  faEnvelope,
  faTimesCircle,
  faLinkedin,
  faPhone,
  faArrowCircleLeft,
  faArrowCircleRight,
  faTwitter
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
