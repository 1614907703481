import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    modalOpen: false,
    loadingSplash: true,
    consent: null
  },
  mutations: {
    toggleModal(state) {
      state.modalOpen = !state.modalOpen;
    },
    closeSplash(state) {
      state.loadingSplash = false;
    },
    setConsent(state, consent) {
      state.consent = consent;
    }
  },
  actions: {},
  modules: {}
});
