function getTitle(vm) {
  const { title } = vm.$options;
  if (title) {
    return typeof title === "function" ? title.call(vm) : title;
  }
}

function getMeta(vm) {
  const { meta } = vm.$options;
  if (meta) {
    return typeof meta === "function" ? meta.call(vm) : meta;
  }
}
export default {
  created() {
    const title = getTitle(this);
    if (title) {
      document.title = title;
    }

    const _desc = getMeta(this);
    if (_desc) {
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", _desc);
    }
  }
};
