<template>
  <div class="relative">
    <menu-bar></menu-bar>
    <hero></hero>
    <div class="w-full bg-black relative pb-12 sm:pb-40">
      <svg
        class="relative -top-4 sm:-top-8 md:-top-12"
        viewBox="0 0 1920 165.71"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              class="cls-1"
              d="M1920,165.71a10976,10976,0,0,0-1263-38q-328.78,9.09-657,38v-140c285.24,9.17,517.84,5.29,678.23,0C1008.68,14.8,1203.67-7.77,1545,2.71c159.51,4.9,290,15.14,375,23Z"
            />
          </g>
        </g>
      </svg>

      <div
        class="container mx-auto mt-8 sm:-mt-20 z-20 relative py-20  lg:py-40 ipad:mt-20"
      >
        <div class="grid grid-cols-12">
          <div
            class="p-8 lg:p-16 xl:p-0 text-center col-span-12 xl:col-span-8  xl:mr-20 h-96 flex justify-center items-start flex-col"
          >
            <h2
              class="text-white font-black text-4xl lg:text-6xl font-impact mb-12"
            >
              Scaffold Consulting
            </h2>
            <dl class="text-white text-left text-xl lg:text-3xl font-bold">
              <dt class="font-bold ">Project Management</dt>
              <dd class="text-lg mb-4">
                RYE Safety provides a nationwide project management solution for
                scaffolding services
                <router-link to="/scaffold">
                  <span class="text-base text-brand-orange block">
                    Learn More
                  </span>
                </router-link>
              </dd>
              <dt class="font-bold ">Health & Safety</dt>
              <dd class="text-lg mb-4">
                Scaffolding Industry specific advice & guidance
                <router-link to="/scaffold">
                  <span class="text-base text-brand-orange block">
                    Learn More
                  </span>
                </router-link>
              </dd>
              <dt class="font-bold ">Temporary Works</dt>
              <dd class="text-lg mb-4">
                Coordination & management for Temporary Works
                <router-link to="/scaffold">
                  <span class="text-base text-brand-orange block">
                    Learn More
                  </span>
                </router-link>
              </dd>
              <dt class="font-bold ">Scaffold Inspection</dt>
              <dd class="text-lg mb-4">
                Routine inspection, reporting and incident reponse
                <router-link to="/scaffold">
                  <span class="text-base text-brand-orange block">
                    Learn More
                  </span>
                </router-link>
              </dd>
            </dl>
          </div>
          <div class="col-span-12  xl:col-span-4 h-96">
            <hooper :settings="imageSliderSettings" class="w-full">
              <slide class="flex justify-center items-center">
                <div class="w-full flex justify-around items-center">
                  <img src="/img/c1.png" class="" />
                </div>
              </slide>
              <slide class="flex justify-center items-center">
                <div class="w-full flex justify-around items-center">
                  <img src="/img/c2.png" class="  " />
                </div>
              </slide>
              <slide class="flex justify-center items-center">
                <div class="w-full flex justify-around items-center">
                  <img src="/img/c3.png" class="  " />
                </div>
              </slide>
              <slide class="flex justify-center items-center">
                <div class="w-full flex justify-around items-center">
                  <img src="/img/c4.png" class="  " />
                </div>
              </slide>
              <slide class="flex justify-center items-center">
                <div class="w-full flex justify-around items-center">
                  <img src="/img/c5.png" class="  " />
                </div>
              </slide>
              <slide class="flex justify-center items-center">
                <div class="w-full flex justify-around items-center">
                  <img src="/img/c6.png" class="  " />
                </div>
              </slide>
            </hooper>
          </div>
        </div>
      </div>
    </div>

    <div class="divider relative">
      <img
        class="w-full relative -top-12 lg:-top-40"
        src="/img/divider.png"
        alt="Divider"
      />
    </div>

    <!-- Scaffolding Consulting-->
    <div
      class="container mx-auto flex justify-around items-center flex-col ipad:flex-col lg:flex-row"
    >
      <div
        class="w-4/5 ipad:w-4/5 mx-auto ipad:mx-auto lg:mx-0 lg:w-2/5 xl:w-1/2 relative"
      >
        <div
          class="absolute z-20 top-1/2 right-0"
          @click="$refs.schooper.slideNext()"
        >
          <font-awesome-icon
            icon="arrow-circle-right"
            class="text-brand-orange"
            size="2x"
          ></font-awesome-icon>
        </div>
        <div
          class="absolute z-20 top-1/2 left-0"
          @click="$refs.schooper.slidePrev()"
        >
          <font-awesome-icon
            icon="arrow-circle-left"
            class="text-brand-orange"
            size="2x"
          ></font-awesome-icon>
        </div>
        <hooper :settings="hooperSettings" ref="schooper">
          <slide>
            <div class="">
              <div class="safety-slider">
                <h5>
                  Temporary Works
                </h5>
                <p>
                  Temporary works (TW) are the parts of a construction project
                  that are needed to enable the permanent works to be built.
                  <br /><br />
                  BS5975:2019 provides an industry consensus view on what is
                  considered to be good practice. The legal requirement is that
                  the party in control must ensure that work is allocated and
                  carried out in a manner that does not create unacceptable risk
                  of harm to workers or members of the public. You must make
                  sure that Temporary Works are properly managed to ensure
                  safety.
                </p>
              </div>
            </div>
          </slide>

          <slide>
            <div class="">
              <div class="safety-slider">
                <h5>
                  Statutory Scaffold Inspection
                </h5>
                <p>
                  Scaffolding Inspections must be carried out by a competent
                  person whose combination of knowledge, training and experience
                  is appropriate for the type and complexity of the scaffold.
                  <br /><br />
                  <span class="font-bold">RYE Safety</span> has the experience
                  to conduct all inspections, whether routine or following
                  potentially risk elevating events. Our inspection reports can
                  highlight defects or matters affecting safety, helping you
                  identify and remedy recurring issues.
                </p>
              </div>
            </div>
          </slide>

          <slide>
            <div class="safety-slider">
              <h5>
                Sector Specific Health & Safety
              </h5>
              <p>
                Although the law requires that employers and self-employed
                contractors assess the risk from work at height and go on to
                organise and plan the work so it is carried out safely, that's
                just one of the factors in ensuring the safety of those working
                with and being in the area of scaffold installations.
                <br /><br />
                Our hands on experience in the Scaffolding Industry puts us in
                the best place to advise you on safety of installation,
                protection of the public and your workforce.
              </p>
            </div>
          </slide>
        </hooper>
      </div>
      <div
        class="flex flex-col justify-center items-center iphone:mt-0 mt-40 ipad:mt-40 lg:mt-0"
      >
        <router-link to="/scaffold">
          <h2 class="font-impact text-7xl">RYE SAFETY</h2>
          <p class="text-3xl inline-block font-impact relative">
            FOR SCAFFOLD CONSULTING<br /><br />
            <span class="w-1/4 inline-block absolute bottom-0 -right-5">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.87 41.68">
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_2-2" data-name="Layer 2">
                    <path
                      class="cls-1"
                      d="M7,.42,6.89,2.64a5.74,5.74,0,0,1,2-2A5.1,5.1,0,0,1,11.46,0a5,5,0,0,1,4.48,2.64,6.42,6.42,0,0,1,2-2A4.87,4.87,0,0,1,20.46,0a4.87,4.87,0,0,1,3,.88A3.83,3.83,0,0,1,25,3a18.34,18.34,0,0,1,.33,4.14V23.75H18.42V8.54a19.71,19.71,0,0,0-.2-3.7.9.9,0,0,0-.94-.71,1,1,0,0,0-1,.7,18.16,18.16,0,0,0-.22,3.71V23.75H9.19V8.93A27.64,27.64,0,0,0,9,4.82a.85.85,0,0,0-.93-.69,1.09,1.09,0,0,0-.81.36,1.37,1.37,0,0,0-.36.88c0,.35,0,1.08,0,2.21V23.75H0V.42Z"
                    />
                    <path
                      class="cls-1"
                      d="M43.82,8.61v5.72a24.32,24.32,0,0,1-.31,4.61,6.6,6.6,0,0,1-1.36,2.74,6.08,6.08,0,0,1-2.66,1.88,10.51,10.51,0,0,1-3.74.61,13.18,13.18,0,0,1-4-.52,5.48,5.48,0,0,1-2.54-1.56,6.26,6.26,0,0,1-1.29-2.53,18.92,18.92,0,0,1-.39-4.46v-6A14.6,14.6,0,0,1,28.25,4a5.83,5.83,0,0,1,2.53-2.93A8.91,8.91,0,0,1,35.46,0a10.75,10.75,0,0,1,4.1.71,6.67,6.67,0,0,1,2.65,1.86,6.54,6.54,0,0,1,1.27,2.35A14.61,14.61,0,0,1,43.82,8.61Zm-7.1-1.75a7.74,7.74,0,0,0-.19-2.26.81.81,0,0,0-.83-.47.83.83,0,0,0-.84.47,7.15,7.15,0,0,0-.21,2.26V17.38a6.89,6.89,0,0,0,.21,2.16.81.81,0,0,0,.82.5.82.82,0,0,0,.84-.46,6.1,6.1,0,0,0,.2-2Z"
                    />
                    <path
                      class="cls-1"
                      d="M53.26.42,53,3.49C54,1.29,55.51.13,57.46,0V8.21a5.83,5.83,0,0,0-2.86.53,2.21,2.21,0,0,0-1.13,1.46,26.58,26.58,0,0,0-.21,4.34v9.21h-7.1V.42Z"
                    />
                    <path
                      class="cls-1"
                      d="M75.13,12.5H65.94v5a5.69,5.69,0,0,0,.22,2A.89.89,0,0,0,67,20a1.06,1.06,0,0,0,1.08-.61,6.33,6.33,0,0,0,.28-2.34V14h6.73v1.72a15.53,15.53,0,0,1-.27,3.32,6.85,6.85,0,0,1-1.28,2.48,6.19,6.19,0,0,1-2.54,2,9.8,9.8,0,0,1-3.86.66,11.28,11.28,0,0,1-4-.65,6.37,6.37,0,0,1-2.68-1.78,6.52,6.52,0,0,1-1.33-2.5,16.41,16.41,0,0,1-.37-4V8.47a11.57,11.57,0,0,1,.83-4.82A5.66,5.66,0,0,1,62.37.94,9.47,9.47,0,0,1,66.69,0a9.72,9.72,0,0,1,4.93,1.13,6.23,6.23,0,0,1,2.73,3,14.11,14.11,0,0,1,.78,5.26ZM68,8.7V7a8.59,8.59,0,0,0-.19-2.34A.77.77,0,0,0,67,4.13c-.5,0-.8.15-.92.45A9.31,9.31,0,0,0,65.94,7V8.7Z"
                    />
                    <line
                      class="cls-2"
                      x1="30.87"
                      y1="31.71"
                      x2="83.52"
                      y2="31.71"
                    />
                    <polygon
                      class="cls-1"
                      points="80.6 41.68 97.87 31.71 80.6 21.73 80.6 41.68"
                    />
                  </g>
                </g>
              </svg>
            </span>
          </p>
        </router-link>
      </div>
    </div>

    <!-- SHRINK WRAP -->
    <div class="w-full mt-60 relative">
      <img
        class="absolute -top-10 z-20 sw-banner"
        style=""
        src="/img/logo-banner.png"
        alt=""
      />
      <img
        class="ml-0 lg:ml-8 xl:ml-0 relative top-4 lg:top-36"
        src="/img/bridge1.png"
        alt=""
      />
      <img src="/img/sw4.png" class="w-3/4 z-30 relative sm:hidden" />
      <img
        class="w-40 sm:w-48 lg:w-auto absolute top-0 right-0 ipad:w-64"
        src="/img/rhino-approved.png"
        alt=""
      />
      <div class="flex justify-between">
        <div class="-mt-16 lg:mt-16 container mx-auto">
          <h2 class="text-5xl mt-36 font-impact text-center w-full sm:w-7/12">
            Why Shrink Wrap?
          </h2>
          <div class="w-full flex justify-around items-center">
            <div class="mt-8 h-full w-10/12 mx-auto sm:w-1/2 relative">
              <div
                class="absolute z-20 top-1/2 right-0"
                @click="$refs.swhooper.slideNext()"
              >
                <font-awesome-icon
                  icon="arrow-circle-right"
                  class="text-brand-orange"
                  size="2x"
                ></font-awesome-icon>
              </div>
              <div
                class="absolute z-20 top-1/2 left-0"
                @click="$refs.swhooper.slidePrev()"
              >
                <font-awesome-icon
                  icon="arrow-circle-left"
                  class="text-brand-orange"
                  size="2x"
                ></font-awesome-icon>
              </div>
              <hooper :settings="hooperSettingsSecond" ref="swhooper">
                <slide>
                  <div class="safety-slider">
                    <h5>
                      Covered In Shrink Wrap is a Specialist Contractor
                    </h5>
                    <p>
                      Many installers of shrink wrap provide the service as a
                      side offering alongside another business. Unfortunately,
                      this doesn't always achieve the best results.<br /><br />
                      At Covered In Shrink Wrap we only offer one service,
                      expert installation of shrink wrap products.
                      <span class="font-bold">Covered In Shrink Wrap</span> has
                      a level of mastery in installation achieved through
                      thorough, continuous training and experience.
                    </p>
                  </div>
                </slide>
                <slide>
                  <div class="safety-slider">
                    <h5>
                      A Low Maintenance Solution
                    </h5>
                    <p>
                      Our shrink wrapping installation process results in a drum
                      tight, robust solution. In addition to our overlapped,
                      welded joints we use a heating process to further shrink
                      all of the panels. Attached to the individual scaffold
                      tubes our product will not flap around in the wind, it
                      will not self destruct. <br /><br />There's no need to pay
                      scaffolders or contractors to repair loose and flapping
                      sheets anymore.
                      <span class="font-bold">Covered In Shrink Wrap</span> can
                      install an effective, robust solution.
                    </p>
                  </div>
                </slide>
                <slide>
                  <div class="safety-slider">
                    <h5>
                      Effective Environmental Protection
                    </h5>
                    <p>
                      Shrink wrapped scaffolding provides effective protection
                      from the weather. Each joint is sealed with a process of
                      heat and pressure that creates a permanent weld. This
                      method allows us to provide a superior level of protection
                      and environmental containment that is effective and low
                      maintenance.<br /><br />
                      The continuously bonded skin can protect the environment
                      against the effects of shot blasting, concrete cutting,
                      asbestos removal and more. Call
                      <span class="font-bold">Covered In Shrink Wrap</span> and
                      meet a higher standard.
                    </p>
                  </div>
                </slide>
                <hooper-progress slot="hooper-addons"></hooper-progress>
              </hooper>
            </div>
            <img
              src="/img/sw4.png"
              alt=""
              class="ml-20 -mt-60 lg:mt-0 w-4/12 relative z-30 hidden sm:block"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-36 sm:-mt-36 lg:mt-20 relative">
      <router-link to="/shrinkwrap">
        <img
          src="/img/sw-divider.png"
          alt=""
          class="-ml-1 w-2/3 transform origin-left scale-150 sm:scale-100"
        />
      </router-link>
      <div
        class="absolute -top-48 sm:right-0 sm:top-8  w-full sm:w-1/2 px-20 flex items-center justify-center"
      >
        <img src="/img/chas.png" alt="" class="w-1/3" />
        <img src="/img/sa-ass.png" alt="" class=" w-1/2 ml-8" />
      </div>
    </div>

    <!-- HEALTH & SAFETY -->
    <div class="w-full -mt-4 bg-black ">
      <img src="/img/section-divider.png" alt="" class="w-full mt-8 sm:mt-0" />
    </div>

    <div class="py-20 2xl:h-192 grid grid-cols-12 bg-black">
      <div
        class="mt-12 ipad:mt-20 lg:mt-0
                      mb-12 ipad:mb-20 lg:mb-0
                     lg:col-start-2 xl:col-start-0
                     col-span-12 ipad:col-span-12 lg:col-span-3 xl:col-span-4
                     flex flex-col justify-center items-center"
      >
        <router-link to="/">
          <h2 class="font-impact text-7xl text-white">RYE SAFETY</h2>
          <p class="font-impact text-4xl relative text-white">
            For Health And Safety
          </p>
        </router-link>
      </div>
      <div
        class="col-start-2 ipad:col-start-2 lg:col-start-6
                        ipad:col-span-10 col-span-10 lg:col-span-6
                        flex justify-center items-center relative"
      >
        <div
          class="absolute z-20 top-1/2 right-0"
          @click="$refs.hshooper.slideNext()"
        >
          <font-awesome-icon
            icon="arrow-circle-right"
            class="text-white"
            size="2x"
          ></font-awesome-icon>
        </div>
        <div
          class="absolute z-20 top-1/2 left-0"
          @click="$refs.hshooper.slidePrev()"
        >
          <font-awesome-icon
            icon="arrow-circle-left"
            class="text-white"
            size="2x"
          ></font-awesome-icon>
        </div>
        <hooper :settings="hooperSettings" ref="hshooper">
          <slide>
            <div class="">
              <div class="safety-slider-invert">
                <h5>
                  Training & Awareness
                </h5>
                <p>
                  The Health and Safety at Work etc Act 1974 requires you to
                  provide whatever information, instruction, training and
                  supervision as is necessary to ensure, so far as is reasonably
                  practicable, the health and safety at work of your employees.
                  <br /><br />
                  Whether you are an employer or self-employed, are you sure
                  that you’re up to date with how to identify the hazards and
                  control the risks from your work?
                  <span class="font-bold">RYE Safety's</span> experience and
                  position in the community is an asset that will provide an
                  effective, up to date and engaging solution to your training
                  and awareness needs.
                  <router-link class="text-brand-orange" to="healthandsafety"
                    >About our Training Packages..</router-link
                  >
                </p>
              </div>
            </div>
          </slide>

          <slide>
            <div class="">
              <div class="safety-slider-invert">
                <h5>
                  Accident & Incident Investigation
                </h5>
                <p>
                  An effective investigation requires a methodical, structured
                  approach to information gathering, collation and analysis. As
                  an experienced Health & Safety Consultancy,
                  <span class="font-bold">RYE Safety</span> can conduct an
                  investigation, the findings of which will help you prevent
                  recurrences of incidents and improve your overall management
                  of risk.<br /><br />
                  Lessons learned from near misses and incidents can help
                  prevent costly mistakes in the future.
                  <router-link to="/contact"
                    ><span class="font-bold"
                      >Contact RYE Safety</span
                    ></router-link
                  >
                  today to begin effective improvement.
                  <router-link class="text-brand-orange" to="healthandsafety"
                    >RYE Safety can help...</router-link
                  >
                </p>
              </div>
            </div>
          </slide>

          <slide>
            <div class="safety-slider-invert">
              <h5>
                Risk Assessments & Method Statement
              </h5>
              <p>
                General, business wide risk assessments and specific area
                assessments will identify the at risk areas of your operation
                and provide a route to safer working. Systems must be in place
                for the protection of your workforce.
                <span class="font-bold">RYE Safety</span> has experience across
                industries and can help you identify threats that otherwise may
                expose your business to unnecessary risk. <br /><br />
                Our method statements describe a logical sequence that show your
                operation has understood the threats to safety in high risk
                working practices. Your method statements should allow you to
                effectively plan work and the health and safety resources
                required.
                <router-link class="text-brand-orange" to="healthandsafety"
                  >More on RAMS...</router-link
                >
              </p>
            </div>
          </slide>

          <hooper-progress slot="hooper-addons"></hooper-progress>
        </hooper>
      </div>
    </div>

    <site-footer></site-footer>
  </div>
</template>

<script>
// @ is an alias to /src

import Hero from "@/components/Hero";
import MenuBar from "@/components/MenuBar";
import { Hooper, Slide, Progress as HooperProgress } from "hooper";
import SiteFooter from "@/components/SiteFooter";
import "hooper/dist/hooper.css";
export default {
  name: "Home",
  title: "RYE Group - Scaffolding, Shrink Wrap, Health & Safety",
  meta:
    "RYE Group expertise: Temporary Works, Health & Safety, Shrink Wrap Applications.  As consultants, RYE Group can help you when you need it.  Contact Us Today, let's start on your next project",
  components: {
    SiteFooter,
    MenuBar,
    Hero,
    Hooper,
    Slide,
    HooperProgress
  },
  data() {
    return {
      hooper: Hooper,
      hooperSettings: {
        infiniteScroll: true,
        autoPlay: true,
        playSpeed: 5000,
        initialSlide: 2,
        wheelControl: false,
        centerMode: true
      },
      hooperSettingsSecond: {
        infiniteScroll: true,
        autoPlay: true,
        playSpeed: 5000,
        initialSlide: 2,
        wheelControl: false,
        itemsToShow: 1
      },
      imageSliderSettings: {
        infiniteScroll: true,
        autoPlay: true,
        playSpeed: 3000,
        transition: 1000,
        wheelControl: false,
        hoverPause: false,
        vertical: false,
        itemsToShow: 2,
        centerMode: true,
        breakpoints: {
          1200: {
            vertical: true,
            itemsToShow: 1,
            centerMode: false
          }
        }
      }
    };
  }
};
</script>

<style scoped>
li {
  @apply my-2;
}
.safety-slider p {
  @apply border-black text-black bg-white text-sm ipad:text-2xl lg:text-lg xl:text-2xl py-4 lg:py-8 px-14;
}

.safety-slider h5 {
  @apply text-white text-xl ipad:text-3xl lg:text-3xl p-4 font-impact;
}

.safety-slider {
  @apply w-full bg-black;
}

.safety-slider-invert p {
  @apply border-black text-white bg-black text-sm ipad:text-2xl lg:text-lg xl:text-2xl py-4 lg:py-8 px-14;
}

.safety-slider-invert h5 {
  @apply text-brand-orange text-xl ipad:text-3xl lg:text-3xl p-4 font-impact;
}

.safety-slider-invert {
  @apply w-full bg-black;
}

.cls-1 {
  fill: #e8732c;
}
.cls-2 {
  fill: none;
  stroke: #e8732c;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.hooper {
  height: 500px;
}
.sw-banner {
  max-width: 100%;
}
@media screen and (min-width: 840px) {
  .sw-banner {
    max-width: 90%;
  }
}
</style>

<style>
.hooper-progress-inner {
  @apply bg-brand-orange;
}
</style>
