<template>
  <footer>
    <div>
      <div
        class="w-full bg-black grid grid-cols-12 text-white font-impact py-20 sm:py-32 lg:py-60"
      >
        <div class="h-0 lg:h-96"></div>
        <div
          class="col-start-1 col-span-12 text-center sm:text-left sm:col-span-3 sm:border-l-4 border-brand-orange text-xl lg:text-3xl xl:text-4xl sm:leading-10 flex justify-center flex-col sm:pl-4 order-2 sm:order-1"
        >
          <router-link to="/scaffold">
            <p class="underline mb-4">Scaffold Consulting</p>
          </router-link>
          <router-link to="/healthandsafety">
            <p class="underline mb-4">Health & Safety</p>
          </router-link>
          <router-link to="/shrinkwrap">
            <p class="mb-4 underline">Shrink Wrapping</p>
          </router-link>
        </div>
        <div
          class="col-span-12 sm:col-span-4 text-center flex flex-col justify-center items-center  mt-12 sm:mt-0 mb-12 sm:mb-0 px-4 sm:px-0 order-1 sm:order-2"
        >
          <p class="font-impact text-4xl lg:text-6xl xl:text-8xl text-white">
            RYE GROUP
          </p>
          <p class="font-impact  text-white">
            Our
            <router-link class="underline" to="/cookie"
              >Cookie Policy</router-link
            >
            explains how we protect your identity
          </p>
        </div>
        <div
          class="text-center sm:text-left col-span-12 cols-start-1 sm:col-start-9 sm:col-span-3 sm:border-r-4 border-brand-orange flex flex-col justify-center items-center mt-8 sm:mt-0 sm:items-end  sm:pr-4 order-3"
        >
          <div>
            <p class="text-2xl lg:text-3xl xl:text-4xl">
              Need More <br />Information?
            </p>
            <router-link to="/contact"
              ><p
                class="text-center xl:text-right text-2xl lg:text-4xl xl:text-5xl mt-4 underline"
              >
                CONTACT <span class="text-brand-orange">US</span>
              </p></router-link
            >
          </div>
        </div>
      </div>
      <div
        class="pt-36 col-span-12 text-center relative bg-black flex justify-center items-center"
      >
        <p
          class="text-white absolute bottom-0  text-center w-full text-sm py-8 px-4"
        >
          Copyright RYESAFETY LTD &copy; UK Registered Company Number: 11385281.
          23 Hartley Gardens, Gloucester, United Kingdom, GL4 4PJ
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "SiteFooter"
};
</script>

<style scoped></style>
