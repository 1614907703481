<template>
  <div
    class="fixed top-0 left-0 w-full z-50 flex justify-between items-center p-2 lg:p-4"
    style="background: rgba(0,0,0,0.9)"
  >
    <div
      class="ml-4 md:ml-12 text-white leading-none text-2xl lg:text-4xl 2xl:text-6xl"
      style="font-family: Impact"
    >
      RYE<br />GROUP
    </div>
    <button @click="$store.commit('toggleModal')">
      <font-awesome-icon
        icon="bars"
        class="mr-4 md:mr-12 fa-2x md:fa-3x text-white"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: "MenuBar"
};
</script>

<style scoped></style>
