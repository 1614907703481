<template>
  <div class="w-full h-screen bg-black fixed z-50 top-0 left-0 xl:px-16">
    <button
      class="absolute top-8 right-8"
      @click="$store.commit('toggleModal')"
    >
      <font-awesome-icon
        icon="times-circle"
        class="text-white"
        size="3x"
      ></font-awesome-icon>
    </button>

    <div
      class="font-impact text-2xl lg:text-4xl xl:text-5xl h-3/4 flex justify-between items-center w-full"
    >
      <ul class="w-full xl:w-auto">
        <li>
          -
          <button @click="$store.commit('toggleModal')">
            <router-link to="/">Home</router-link>
          </button>
        </li>
        <li>
          -
          <button @click="$store.commit('toggleModal')">
            <router-link to="/shrinkwrap">Shrink Wrapping</router-link>
          </button>
        </li>
        <li>
          -
          <button @click="$store.commit('toggleModal')">
            <router-link to="/scaffold">Scaffold Consulting</router-link>
          </button>
        </li>
        <li>
          -
          <button @click="$store.commit('toggleModal')">
            <router-link to="/healthandsafety">Health & Safety</router-link>
          </button>
        </li>
        <li>
          -
          <button @click="$store.commit('toggleModal')">
            <router-link to="/contact">Contact Us</router-link>
          </button>
        </li>
      </ul>

      <div
        class="flex-col justify-between items-end w-full xl:w-auto pr-8 hidden md:flex"
      >
        <img
          src="/img/shrink.png"
          alt=""
          class="md:w-1/2 w-3/4 lg:w-3/4 mb-20 md:mb-0 md:mt-20 lg:mt-0 lg:mb-20"
        />
        <img src="/img/rye-white.png" alt="" class="md:w-1/2 w-3/4 lg:w-3/4" />
      </div>
    </div>

    <div
      class="pb-32 sm:pb-0 px-8  xl:px-28 mb-4
   absolute bottom-0 left-0
   w-full
   flex justify-between items-start md:items-center lg:items-start flex-col sm:flex-row lg:flex-col
   text-white font-impact
   "
    >
      <a href="https://twitter.com/Coveredinwrap" target="_blank"
        ><div class="linked">
          <font-awesome-icon
            :icon="['fab', 'twitter']"
            class="text-white"
            size="2x"
          ></font-awesome-icon>
          <span class="text-lg ml-2">@coveredinwrap</span>
        </div></a
      >
      <a href="https://linkedin.com/in/rene-peters-b241681a1" target="_blank">
        <div class="linked">
          <font-awesome-icon
            :icon="['fab', 'linkedin']"
            class="text-white"
            size="2x"
          ></font-awesome-icon>
          <span class="text-lg ml-2">RYE Safety</span>
        </div>
      </a>
      <a
        href="https://www.linkedin.com/company/coveredinshrinkwrap-ltd"
        target="_blank"
        ><div class="linked">
          <font-awesome-icon
            :icon="['fab', 'linkedin']"
            class="text-white"
            size="2x"
          ></font-awesome-icon>
          <span class="text-lg ml-2">CoveredInShrinkWrap</span>
        </div></a
      >
      <div class="linked">
        <font-awesome-icon
          icon="envelope"
          class="mr-2"
          size="2x"
        ></font-awesome-icon
        ><a href="mailto:info@rye.group" class="text-lg">INFO@RYE.GROUP</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  components: {}
};
</script>

<style scoped>
ul {
  @apply ml-6 sm:ml-12;
}
li {
  @apply py-1 lg:py-4 hover:text-brand-orange;
}
div.linked {
  @apply py-1 hover:text-brand-orange flex items-center;
}
</style>
